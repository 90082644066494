/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it

exports.onClientEntry = () => {
  // Custom redirection logic
  if (window.location.pathname === '/luxurydiversityandinclusion/') {
    window.location.replace('https://thefreshkid.com/luxury-diversity-and-inclusion/');

    // Adding a delay before reloading the new page
    setTimeout(() => {
      window.location.reload();
    }, 1000); // You can adjust the delay time (in milliseconds) as needed
  }



  if (window.location.pathname === '/luxuryfashion/') {
    window.location.replace('https://thefreshkid.com/the-fresh-kid/');

    // Adding a delay before reloading the new page
    setTimeout(() => {
      window.location.reload();
    }, 1000); // You can adjust the delay time (in milliseconds) as needed
  }

  if (window.location.pathname === '/luxury-resale-report/') {
    window.location.replace('https://thefreshkid.com/luxury-circulareconomy-benefits/');

    // Adding a delay before reloading the new page
    setTimeout(() => {
      window.location.reload();
    }, 1000); // You can adjust the delay time (in milliseconds) as needed
  }
};